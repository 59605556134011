import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import propTypes from "prop-types";
import { useState } from "react";
import { formatCurrencyNoDecimal } from "../../../helper/formatCurrency";

export default function TableResumen({ data }) {
  const [infoShow] = useState(data);

  return (
    <div className=" max-w-4xl flex flex-col justify-end gap-3 flex-wrap">
      <p className="text-sm font-semibold">{infoShow.title}</p>
      <Table isStriped aria-label="Example static collection table" hideHeader>
        <TableHeader>
          <TableColumn>NAME</TableColumn>
          <TableColumn>ROLE</TableColumn>
        </TableHeader>
        <TableBody>
          {infoShow.table.map((element) => {
            return (
              <TableRow key={`${element.account_description}-${element.code}`}>
                <TableCell className="w-1 text-xs">
                  {element.account_description}
                </TableCell>
                <TableCell className="text-end text-base">
                  {formatCurrencyNoDecimal((element.amount_total / 1000000).toFixed(2))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

TableResumen.propTypes = {
  data: propTypes.array.isRequired,
};
