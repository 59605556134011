import { useEffect, useState } from 'react'
import { getRentabilidadBanca } from '../../services/RentabilidadBanca/rentabilidadBanca_helper'
import TableAutomatic from '../seguros/component/TableAutomatic';
import { convertSlug } from '../../helper/formatCurrency';


export default function RentabilidadBanca() {

    const [columns, setColumns] = useState([])
    const [rentabilidadBanca, setRentabilidadBanca] = useState([])
    const [initialVisibleColumns, setInitialVisibleColumns] = useState([])


    const getInfo = async () => {
        const data = await getRentabilidadBanca()
        const formatColumns = []
        const formatRows = []
        const columnVisible = []
        Object.entries(data.data[0].head).forEach(([, value]) => {
            columnVisible.push(convertSlug(value))
            formatColumns.push({
                name: value,
                uid: convertSlug(value),
                sortable: false,
                maxWidth: "10px"
            })
        })

        data.data[0].rows.forEach((element, index) => {
            const row = {
                id: index
            }
            Object.entries(element).forEach(([key, value]) => {
                row[convertSlug(key)] = value

            })
            formatRows.push(row)
        })


        console.log('formatRows', formatRows)
        setInitialVisibleColumns(columnVisible)
        setColumns(formatColumns)
        setRentabilidadBanca(formatRows)
    }

    useEffect(() => {
        getInfo()
    }, [])





    return (
        <div>
            {columns.length > 0 && (
                <TableAutomatic info={{ initialVisibleColumns: initialVisibleColumns }} columns={columns} users={rentabilidadBanca} rowsPerPageP={10} selectRow={[]} />

            )}
        </div>
    )
}
