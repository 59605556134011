import * as  request from "../../helper/request";
import {
    GET_SERVICIO_MEDICO,
    GET_SERVICIO_MEDICO_INVERSIONES,
    GET_SERVICIO_MEDICO_INVERSIONES_COMPANIA
} from "../helper_url";

export const servicioMedico = (data) => {
    console.log('table')
    return request.aPIClient.get(GET_SERVICIO_MEDICO.replace(":table", data));
}

export const servicioMedicoInversiones = (data) => {
    return request.aPIClient.get(GET_SERVICIO_MEDICO_INVERSIONES, data);
}

export const servicioMedicoInversionesCompania = (data) => {
    return request.aPIClient.get(GET_SERVICIO_MEDICO_INVERSIONES_COMPANIA, data);
}