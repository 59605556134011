import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import { formatDataLiner } from "./helper/formatData";
moment.locale("es");

export default function TotalColocaciones({ colocacionesTotal }) {
  const [options, setOptions] = useState({});
  const [loader, setLoader] = useState(false);

  const formatData = (data) => {
    const newData = [];

    data.forEach((element) => {
      const Transaction = [];

      const filterCode145000000 = element.Transaction.find(
        (e) => e.code_account === "145000000"
      );
      const filterCode146000000 = element.Transaction.find(
        (e) => e.code_account === "146000000"
      );
      const filterCode148000000 = element.Transaction.find(
        (e) => e.code_account === "148000000"
      );
      const filterCode149500100 = element.Transaction.find(
        (e) => e.code_account === "149500100"
      );
      const filterCode149600100 = element.Transaction.find(
        (e) => e.code_account === "149600100"
      );
      const filterCode149700100 = element.Transaction.find(
        (e) => e.code_account === "149700100"
      );

      Transaction.push({
        ...filterCode145000000,
        amount_total:
          filterCode145000000.amount_total +
          filterCode149500100.amount_total +
          (filterCode148000000.amount_total +
            filterCode149700100.amount_total) +
          (filterCode146000000.amount_total + filterCode149600100.amount_total),
      });

      newData.push({ ...element, Transaction });
    });

    setLoader(true);
    const { legend, xAxis, series } = formatDataLiner(newData);

    setOptions({
      tooltip: {
        trigger: "axis",
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        min: function (value) {
          if (value.min - 5 < 0) {
            return 0;
          }
          return (value.min - 5).toFixed(0);
        },
      },
      legend,
      xAxis,
      series,
    });

    setLoader(false);
  };

  useEffect(() => {
    formatData(colocacionesTotal);
  }, [colocacionesTotal]);

  return (
    <div>
      <h1 className="text-xl font-bold mb-5">Total Colocaciones</h1>
      {loader ? null : (
        <ReactEcharts
          style={{ height: "300px" }}
          option={options}
          notMerge={true}
        />
      )}
    </div>
  );
}

TotalColocaciones.propTypes = {
  colocacionesTotal: PropTypes.array.isRequired,
};
