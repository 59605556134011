import { Card, CardBody, CircularProgress } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { getRentabilidadBanca } from '../../services/RentabilidadBanca/rentabilidadBanca_helper'

import { getInfoBankResumen } from '../../services/summaries/summaries';



export default function ResumenGeneral() {

    const [info, setInfo] = useState([])
    const [resumenColocaciones, setResumenColocaciones] = useState([])
    const [loader, setLoader] = useState(true)

    const getData = async () => {
        const data = await getRentabilidadBanca()

        // const mark = renderMarkdownToHTML(data.data[0].resumen);
        const colocaciones = await getInfoBankResumen();



        setResumenColocaciones([{ __html: colocaciones.res[0].resumen.replace(/\n/g, "<br>").replace(/-(.*?):/g, '<strong>$1:</strong>') }])

        setInfo({
            __html: data.data[0].resumen.replace(/\n/g, "<br>").replace(/-(.*?):/g, '<strong>$1:</strong>')
        })
        setLoader(false)
    }

    useEffect(() => {
        setLoader(true)
        getData()
    }, [])



    return (
        <>
            {loader && (
                <div className="w-full flex items-center justify-center">
                    <CircularProgress color="c-primary" classNames={{
                        svg: "w-36 h-36 drop-shadow-md",
                        track: "stroke-white/10",
                        value: "text-3xl font-semibold text-white",
                    }} />
                </div>
            )}

            {!loader && (
                <div>
                    <div className="flex flex-col gap-5">
                        <h3 className="text-xl font-bold">Resumen de Colocaciones</h3>
                        {resumenColocaciones.length > 0 && (

                            <Card style={{
                                flexGrow: 1, maxWidth: "100%", minHeight: "100%",
                            }}>
                                <CardBody className="flex flex-col justify-between gap-2">

                                    <div dangerouslySetInnerHTML={{
                                        __html: resumenColocaciones[0].__html
                                    }} />

                                </CardBody>
                            </Card>
                        )}
                    </div>

                    <div className="flex pt-4 flex-col gap-5">
                        <h3 className="text-xl font-bold">Resumen de Rentabilidad Banca</h3>
                        <Card style={{
                            flexGrow: 1, maxWidth: "100%", minHeight: "100%",
                        }}>
                            <CardBody className="flex flex-col justify-between gap-2">

                                <div dangerouslySetInnerHTML={{
                                    __html: info.__html
                                }} />

                            </CardBody>
                        </Card>
                    </div>

                </div>
            )}

        </>

    )
}
