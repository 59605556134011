import moment from "moment";


export function formatDataTransaction(data) {
    const legend = [];
    const series = [];
    const xAxis = [];

    data.forEach((element) => {
        const nameXAxis = `${element.year} - ${moment().set("month", element.month - 1).locale("es").format("MMMM")}`;

        const date = moment()
            .set({
                month: element.month - 1,
                year: element.year,
            })
            .format("YYYY-MM-DD");

        if (!legend.includes(element.name)) {
            legend.push(element.name);
        }

        const existingXAxis = xAxis.find((x) => x.name === nameXAxis);
        if (!existingXAxis) {
            xAxis.push({
                name: nameXAxis,
                year: element.year,
                month: element.month,
                date,
            });
        }

        const existingSeries = series.find((x) => x.name === element.name);
        if (!existingSeries) {
            series.push({
                name: element.name,
                value: [{
                    total: Number((element.Transaction[0].amount_total / 1000000000000).toFixed(2)),
                    month: element.month,
                    year: element.year,
                    date,
                }],
            });
        } else {
            existingSeries.value.push({
                total: Number((element.Transaction[0].amount_total / 1000000000000).toFixed(2)),
                month: element.month,
                year: element.year,
                date,
            });
        }
    });

    series.forEach((element) => {
        element.value.sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    xAxis.sort((a, b) => new Date(a.date) - new Date(b.date));

    return {
        legend,
        xAxis,
        series,
    };
}

export function formatDataLiner(data) {
    const {
        leyend, xAxis, series
    } = formatDataTransaction(data);

    const seriesData = series.map((e) => {
        return {
            name: e.name,
            type: 'line',

            data: e.value.map((a) => {
                return a.total;
            }),
            label: {
                show: true,
                position: "bottom",
                textStyle: {
                    fontSize: 10,
                },
            },
            labelLayout: { hideOverlap: true, dy: 10, moverOverlap: 'shiftY' }
        };
    })

    const leyendFormat = {
        data: leyend,

        textStyle: {
            fontSize: 10,
        },
        formatter: function (name) {
            var maxLength = 10;
            if (name.length > maxLength) {
                var spaceIndex = name.lastIndexOf(' ', maxLength);
                if (spaceIndex > 0) {
                    // Divide las leyendas en dos líneas en el espacio más cercano al tamaño máximo
                    return name.substring(0, spaceIndex) + '\n' + name.substring(spaceIndex + 1);
                }
            }
            return name;
        }

    }

    const xAxisFormat = {
        type: "category",
        boundaryGap: false,
        data: xAxis.map((e) => {
            return e.name;
        }),
    }

    console.log("seriesData.length > 0",seriesData.length > 0)
    console.log("seriesData[0]",seriesData[0])


    if(seriesData.length > 0 && seriesData[0].data.length > 13){
         seriesData.forEach(element => {
            element.data.shift();
        }); 
        xAxisFormat.data.shift();
    }
    

    return {
        legend: leyendFormat,
        xAxis: xAxisFormat,
        series: seriesData
    }
}

export const formatDataBar = (data) => {
    const {
        leyend, series
    } = formatDataTransaction(data);

    const xAxis = {
        type: "category",
        data: [],
        axisLabel: {
            top: 10,
            interval: 0, // Muestra todas las etiquetas del eje x
            rotate: 45, // Rota las etiquetas en un ángulo de 45 grados
            padding: [0, 20, 0],
            formatter: function (value) {
                // Divide los nombres en varias líneas si son muy largos
                var maxLength = 10;
                if (value.length > maxLength) {
                    return value.replace(/(.{10})/g, '$1\n');
                }
                return value;
            }
        }

    }

    const seriesData = {
        type: 'bar',
        stack: "Total",
        data: [],
        textStyle: {
            fontSize: 5
        },

        label: {
            show: true,
            position: "top",
            textStyle: {
                fontSize: 10,
            },
        },
    };

    series.forEach((e) => {
        xAxis.data.push(e.name)
        seriesData.data.push(e.value[0].total)

    })




    return {
        legend: leyend,
        xAxis: xAxis,
        series: seriesData
    }
}

export function formatDataTransactionSpearedFromYear(data) {
    const leyend = [];
    const dataSeries = [];
    const xAxis = [];
    const nameXAxisBank = [];

    data.forEach((element) => {
        const nameXAxis =
            moment()
                .set("month", element.month - 1)
                .locale("es")
                .format("MMMM");

        const date = moment()
            .set({
                month: element.month - 1,
                year: element.year,
            })
            .format("YYYY-MM-DD");

        if (!leyend.includes(element.name)) {
            leyend.push(element.name);
            nameXAxisBank.push(element.name);
        }

        if (!xAxis.find((x) => x.name === nameXAxis)) {
            xAxis.push({
                name: nameXAxis,
                year: element.year,
                month: element.month,
                date,
            });
        }

        const findDataSeries = dataSeries.find((x) => x.name === element.name + " " + element.year && x.year === element.year);

        if (!findDataSeries) {
            dataSeries.push({
                name: element.name + " " + element.year,
                year: element.year,
                month: element.month,
                value: [
                    {
                        total: Number((
                            element.Transaction[0].amount_total / 1000000000000
                        ).toFixed(2)),
                        month: element.month,
                        year: element.year,
                        date,
                    },
                ],
            });
        } else {
            findDataSeries.value.push({
                total: Number((element.Transaction[0].amount_total / 1000000000000).toFixed(
                    2
                )),
                month: element.month,
                year: element.year,
                date,
            });
        }
    });




    dataSeries.forEach((element) => {
        element.value.sort((a, b) => new Date(a.date) - new Date(b.date));
    });


    xAxis.sort((a, b) => a.month - b.month);


    return {
        legend: leyend,
        xAxis: xAxis,
        series: dataSeries
    }

}

export function formatDataLinerSpearedFromYear(data) {
    const { leyend, xAxis, series } = formatDataTransactionSpearedFromYear(data);

    const seriesData = series.map((e) => ({
        name: e.name,
        type: "line",

        data: e.value.map((a) => a.total),
        label: {
            position: 'bottom',
            show: true,
        },
        labelLayout: { hideOverlap: true, dy: 10, moverOverlap: 'shiftY' }
    }));

    const leyendFormat = {
        data: leyend,
        textStyle: {
            fontSize: 10,
        },
        formatter: (name) => {
            const maxLength = 10;
            if (name.length > maxLength) {
                const spaceIndex = name.lastIndexOf(' ', maxLength);
                if (spaceIndex > 0) {
                    return `${name.substring(0, spaceIndex)}\n${name.substring(spaceIndex + 1)}`;
                }
            }
            return name;
        }
    }

    const xAxisFormat = {
        type: "category",
        boundaryGap: false,
        data: xAxis.map((e) => e.name),
    }

    return {
        legend: leyendFormat,
        xAxis: xAxisFormat,
        series: seriesData
    }
}

/**
 * Formats the stacked bar data.
 *
 * @param {type} data - The data to format.
 * @return {type} The formatted data.
 */
export const formatStackedBar = (data, selecTransaction = []) => {
    const xAxis = {
        type: 'category',
        axisTick: {
            alignWithLabel: true
        },
        axisLabel: {
            show: true,
            interval: 0,
            fontSize: 8,
            overflow: 'break',
        },


        data: [],
    }

    const dataSeries = [];

    let sumMax = [];

    const trasactions = []

    data.forEach((element) => {
        element.Transaction.forEach((e) => {
            trasactions.push({ ...e, bank: element.name })
        })
    })


    console.log('data', data)

    const transactions = new Set()

    data.forEach((element, index) => {
        xAxis.data.push(element.name)
        if (!sumMax[index]) {
            sumMax[index] = 0
        }

        element.Transaction.forEach((e) => {
            transactions.add(e.account_description + " " + e.code_account)

            if (selecTransaction.length > 0 && !selecTransaction.includes(e.account_description + " " + e.code_account)) {
                return
            }
            const filterAccountDescription = dataSeries.findIndex(x => x.code === e.code_account);
            if (filterAccountDescription === -1) {
                dataSeries.push({
                    name: e.account_description + " " + e.code_account,
                    code: e.code_account,
                    type: 'bar',
                    stack: 'total',
                    /*   label: {
                          show: true
                      }, */
                    emphasis: {
                        focus: 'series'
                    },
                    //percentage 
                    data: [(e.amount_total)],

                });
                sumMax[index] += (Number((e.amount_total)))
            } else {
                dataSeries[filterAccountDescription].data.push((e.amount_total));
                sumMax[index] += Number((e.amount_total))
            }

        })
    });


    const dataSeriesNew = []

    console.log('dataSeries', dataSeries)

    dataSeries.forEach((e) => {
        //numero maximo del array positivo


        const data = e.data.map((e, key) => {

            if ((e > 0 || e < 0) && e !== Infinity && e !== -Infinity && sumMax[key] !== 0) {
                const percentage = ((Number(e) / sumMax[key]) * 100).toFixed(2)
                if (e < 0) {
                    console.log('e', e)
                    console.log('sumMax[key]', sumMax[key])
                    console.log('percentage', percentage)
                }



                if (percentage > 100) {
                    return 0
                }

                return percentage
            }
            return 0
        })

        dataSeriesNew.push({
            ...e,
            data,
        })

    })



    return [
        {
            tooltip: {
                /*   trigger: 'axis', */
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },




            grid: {
                height: '90%',
                left: '0%',
                right: '0%',
                bottom: '3%',
                containLabel: true
            },
            yAxis: {
                type: 'value',
                max: 100,
                min: -10,
                axisLabel: {
                    formatter: function (params) {
                        return params + "%";
                    },
                },
            },
            xAxis,
            series: dataSeriesNew
        }, transactions]

}

export const formatStackedBarTwo = (data) => {

    const format = []

    data.forEach((e) => {
        e.Transaction.forEach((element) => {
            format.push({
                ...element,
                bank: e.name,
            })
        })
    })

    const legend = []
    const formatData = [];

    const sumMax = [];
    console.log('data', format)


    format.forEach((e) => {
        if (!legend.includes(e.account_description + ' -' + e.code_account)) {
            legend.push(e.account_description + ' -' + e.code_account)
        }
        const filter = formatData.findIndex((x) => x.bank === e.bank)
        const findIndexSumMax = sumMax.findIndex((x) => x.code === e.account_description + ' -' + e.code_account)

        if (findIndexSumMax === -1) {
            sumMax.push({
                code: e.account_description + ' -' + e.code_account,
                dataAmount: Number(e.amount_total)
            })
        } else {
            sumMax[findIndexSumMax].dataAmount += Number(e.amount_total)
        }


        if (filter === -1) {
            formatData.push({
                name: e.bank,
                code: e.code_account,
                type: 'bar',
                stack: 'total',
                /*   label: {
                      show: true
                  }, */
                emphasis: {
                    focus: 'series'
                },
                bank: e.bank,
                data: [
                    Number(e.amount_total),

                ],
            })
        } else {
            formatData[filter].data.push(
                Number(e.amount_total),
            )
        }

    })

    console.log('sumMax', sumMax)

    const formatPercentege = []

    formatData.forEach((e) => {
        const data = []

        e.data.forEach((element, index) => {
            if (sumMax[index].dataAmount === 0) {
                data.push(0)
                return
            }
            const percentage = ((Number(element) / sumMax[index].dataAmount) * 100).toFixed(2)
            if (percentage > 100 || percentage < 0) {
                data.push(0)
                return
            }


            data.push(percentage)
        })

        formatPercentege.push({
            ...e,
            data
        })
    })

    console.log('formatPercentege', formatPercentege)

    return {
        tooltip: {
            /*   trigger: 'axis', */
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },

        grid: {
            height: '100%',
            left: '0%',
            right: '0%',
            bottom: '3%',
            containLabel: true
        },
        yAxis: {
            type: 'value',

            axisLabel: {
                formatter: function (params) {
                    return params + "%";
                },
            },
        },
        xAxis: {
            type: 'category',
            data: legend,
            axisLabel: {
                top: 10,
                interval: 0, // Muestra todas las etiquetas del eje x
                rotate: 90, // Rota las etiquetas en un ángulo de 45 grados
                padding: [0, 20, 0],
                formatter: function (value) {
                    // Divide los nombres en varias líneas si son muy largos
                    var maxLength = 10;
                    if (value.length > maxLength) {
                        return value.replace(/(.{10})/g, '$1\n');
                    }
                    return value;
                }
            }
        },
        series: formatPercentege
    }

}