import { useEffect, useState } from "react";
import TableResumen from "./TableResumen";
import { getInfoBankTransaction } from "../../../services/bankTransaction/bankTransaction";
import HistoricoColocaciones from "../../charts/HistoricoColocaciones";
import { Card, CardBody, CircularProgress } from "@nextui-org/react";
import { formatCurrencyNoDecimal } from "../../../helper/formatCurrency";

export default function Resumen() {
  const [info, setInfo] = useState([]);
  const [bciTile, setBciTile] = useState();
  const [bci, setBci] = useState([]);
  const [historicoBciState, setHistoricoBciState] = useState([]);
  const [loader, setLoader] = useState(false);

  const infoResumen = async () => {
    const infoBci = getInfoBankTransaction({
      codes: ["100000000", "200000000", "300000000", "350000000"],
      year: [2024],
      month: [9],
      banks: ["001", "012", "037"],
    });

    const infoBciTwo = await getInfoBankTransaction({
      codes: ["100000000", "200000000", "300000000", "350000000"],
      year: [2024],
      month: [9],
      banks: ["016"],
    });

    if (infoBciTwo.res) {
      const formatTransaction = [];
      const orderTrnsaction = [
        "TOTAL-ACTIVOS",
        "TOTAL-PASIVOS",
        "PATRIMONIO",
        "UTILIDAD-(PÉRDIDA)-DEL-EJERCICIO",
      ];

      orderTrnsaction.forEach((element) => {
        infoBciTwo.res[0].Transaction.map((elementT) => {
          console.log(elementT.slug_account_description, element);
          if (
            elementT.slug_account_description.toLowerCase() ==
            element.toLowerCase()
          ) {
            formatTransaction.push(elementT);
          }
        });
      });

      console.log(formatTransaction);

      setBciTile(infoBciTwo.res[0].name);
      setBci(formatTransaction);
    }

    const promisees = await Promise.all([infoBci]);

    const formatData = [];

    promisees.forEach((element) => {
      element.res.forEach((element) => {
        formatData.push({
          title: element.name,
          table: element.Transaction.sort((a, b) => {
            return a.code_account - b.code_account;
          }),
        });
      });
    });
    setLoader(false);
    setInfo(formatData);
  };

  const promisseAll = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const lastYear = new Date().getFullYear() - 1;
      const twoYearsAgo = new Date().getFullYear() - 2;

      const historicoBci = await getInfoBankTransaction({
        codes: [
          "145000000",
          "149500100",
          "148000000",
          "149700100",
          "146000000",
          "149600100",
        ],
        year: [twoYearsAgo, currentYear, lastYear],
        banks: ["016"],
      });

      setHistoricoBciState(historicoBci.res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoader(true);
    infoResumen();
    promisseAll();
  }, []);

  return (
    <>
      {loader && (
        <div className="w-full flex items-center justify-center">
          <CircularProgress
            color="c-primary"
            classNames={{
              svg: "w-36 h-36 drop-shadow-md",
              track: "stroke-white/10",
              value: "text-3xl font-semibold text-white",
            }}
          />
        </div>
      )}

      {!loader && (
        <div>
          <div className="flex flex-col gap-3">
            <h6 className="font-semibold text-base">{bciTile}</h6>
            <div className="flex items-stretch gap-3 justify-around mb-10 flex-wrap">
              {bci.map((element) => {
                return (
                  <Card
                    key={element.account_description}
                    style={{
                      flexGrow: 1,
                      maxWidth: "calc(100% / 4)",
                      minHeight: "100%",
                    }}
                  >
                    <CardBody className="flex flex-col justify-between gap-2">
                      <p className="font-bold text-sm">
                        {element.account_description}:
                      </p>
                      <p className="text-lg text-end">
                        {formatCurrencyNoDecimal(
                          (element.amount_total / 1000000).toFixed(2)
                        )}
                      </p>
                    </CardBody>
                  </Card>
                );
              })}
            </div>
          </div>
          <div className="flex items-end gap-8 justify-between mb-10">
            {info.map((element) => {
              return (
                <div key={element.title} style={{ width: "calc(100% / 3)" }}>
                  <TableResumen data={element} key={element.title} />
                </div>
              );
            })}
          </div>

          <HistoricoColocaciones data={historicoBciState} />
        </div>
      )}
    </>
  );
}
