/* eslint-disable react-hooks/exhaustive-deps */
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useState } from "react"
import { servicioMedico } from "../../services/seguros-generales/seguros"
import TableAutomatic from "./component/TableAutomatic";
import { CircularProgress, Select, SelectItem } from "@nextui-org/react";

//const columsTwo = ["Razón social", "5.10.00.00 Total activo", "5.21.00.00 Total pasivo", "5.22.00.00 Total patrimonio", "5.31.62.00 Utilidad (pérdida) por unidades reajustables"]
const columsTwo = ["Razón social", "Total activo", "Total pasivo", "Total patrimonio", "Utilidad (pérdida)"]

export default function SegurosGenerales() {

    const [info, setInfo] = useState([]);
    const [graph, setGraph] = useState();

    const [bancos, setBancos] = useState([]);
    const [dataGraficaFull, setDataGraficaFull] = useState([]);
    const [BanksSelect, setBanksSelect] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectRow, setSelectRow] = useState([]);


    const convertSlug = (str) => {
        return str
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
    }


    const getData = async (texto, columns = []) => {
        try {
            const dataServicioMedico = await servicioMedico(texto)
            const colums = new Set();
            const data = [];
            const initialVisibleColumns = [];
            const pattern = /\.(?=\d)/g;
            dataServicioMedico.data[0].data.forEach((element, index) => {
                const itemT = {
                    id: index
                };

                element.forEach((item, index) => {
                    const regex = /\b\d+\b|\./g;
                    const result = item.Table.replace(regex, '');
                    let columnTble = result.trim();

                    if (columnTble === "Utilidad (pérdida) por unidades reajustables") {
                        columnTble = "Utilidad (pérdida)"
                    }

                    colums.add(columnTble);
                    const value = item.Value.replace(pattern, "");

                    if (value === "BCI") {
                        setSelectRow(new Set([String(index - 1)]))
                        const prueba = new Set()
                        prueba.size
                    }

                    itemT[convertSlug(columnTble)] = isNaN(value) ? value : parseFloat(value);
                })
                data.push(itemT)
            });


            const formatColumns = Array.from(colums).map((column) => {
                if (columns.length > 0) {
                    if (columns.includes(column)) {
                        initialVisibleColumns.push(convertSlug(column))
                    }
                } else {
                    if (initialVisibleColumns.length < 5) {
                        initialVisibleColumns.push(convertSlug(column))
                    }

                }

                return {
                    uid: convertSlug(column),
                    name: column,
                    sortable: true
                };
            })

            return { columns: formatColumns, rows: data, initialVisibleColumns }


        } catch (error) {
            console.log(error)
            return { error }
        }

    }


    const formatData = useCallback(async (data) => {
        const pattern = /\.(?=\d)/g;
        const banks = []
        const bancksSe = []

        const format = [];

        data.forEach((element) => {
            const data = {
                transaction: [],
                total: 0,
            }
            element.forEach((item,) => {
                if (item.Table === "Cuentas") {
                    banks.push(item.Value)
                    data.cuenta = item.Value


                    return
                }

                if (item.Table === "TOTAL") {
                    const value = item.Value.replace(pattern, "");
                    data.total = !value ? 0 : parseFloat(value)
                }

                if (item.Table === "Tipo de compañía" || item.Table === "Inversiones / Resumen de Inversiones" || item.Table === "TOTAL") {
                    return
                }


                if (!item.Value) {
                    data.transaction.push({
                        code_account: item.Table,
                        amount: 0
                    })

                    return
                }
                const value = item.Value.replace(pattern, "");
                data.transaction.push({
                    code_account: item.Table,
                    amount: !value ? 0 : parseFloat(value),
                })

            })

            format.push(data)
        })

        let sumMax = [];
        const dataSeries = []
        const xAxis = {
            type: 'category',
            data: [],
            axisLabel: {
                top: 10,
                // Muestra todas las etiquetas del eje x
                rotate: 90, // Rota las etiquetas en un ángulo de 45 grados

                formatter: function (value) {
                    // Divide los nombres en varias líneas si son muy largos
                    var maxLength = 10;
                    if (value.length > maxLength) {
                        return value.replace(/(.{10})/g, '$1\n');
                    }
                    return value;
                }
            }
        }



        const formatSort = format.sort((a, b) => {
            return b.total - a.total
        })


        let skip = 0;

        formatSort.forEach((element, index) => {
            const word = 'TOTAL';
            const regex = new RegExp(`\\b${word}\\b`, 'i');
            if (BanksSelect.length === 0 && (regex.test(element.cuenta) || sumMax.length > 20)) {
                skip++
                return
            }

            if (BanksSelect.length > 0 && !BanksSelect.includes(element.cuenta)) {
                skip++
                return
            }

            bancksSe.push(element.cuenta)

            xAxis.data.push(element.cuenta)
            sumMax[index - skip] = 0
            element.transaction.forEach((item) => {
                const find = dataSeries.find((e) => e.name === item.code_account)


                if (!find) {
                    dataSeries.push({
                        name: item.code_account,
                        type: 'bar',
                        stack: 'total',
                        index,
                        emphasis: {
                            focus: 'series'
                        },
                        data: [item.amount],

                    })
                    sumMax[index - skip] += item.amount
                } else {
                    find.data.push(item.amount)
                    sumMax[index - skip] += item.amount
                }
            })
        })
        const dataSeriesNew = []


        dataSeries.forEach((e) => {
            const data = e.data.map((e, key) => {


                if ((e > 0 || e < 0) && e !== Infinity && e !== -Infinity && sumMax[key] !== 0) {
                    const percentage = ((Number(e) / sumMax[key]) * 100)


                    if (percentage > 100) {
                        return 0
                    }
                    return percentage
                }
                return 0
            })

            dataSeriesNew.push({
                ...e,
                data,
            })

        })

        const options = {
            tooltip: {
                trigger: 'item',
                valueFormatter: function (value) {
                    return value.toFixed(2) + '%';
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: "20%",
                containLabel: true
            },
            yAxis: {
                max: 100,
                minInterval: 25,
            },
            xAxis,
            series: dataSeriesNew
        }


        if (bancos.length === 0) {
            setBanksSelect(bancksSe)
        }

        setGraph(options)
        setBancos(banks)

    }, [BanksSelect, bancos])


    const gestoDeDatos = async () => {
        const listInto = []
        const dataOne = await getData("seguros-generales-estados-financieros-resumen-por-compania", columsTwo)
        const dataTwo = await servicioMedico("seguros-generales-inversiones-por-compania")

        if (!dataOne.error) {
            listInto.push({ ...dataOne, title: "Balance de seguros" })
        }

        if (!dataTwo.error) {
            setDataGraficaFull(dataTwo.data[0].data)
            formatData(dataTwo.data[0].data)
            // listInto.push({ ...dataTwo, title: "Inversiones por Compañía" })
        }

        setInfo(listInto);
        setLoader(false)

    }



    useEffect(() => {
        setLoader(true)

        gestoDeDatos()
    }, [])

    useEffect(() => {
        formatData(dataGraficaFull)
    }, [BanksSelect, dataGraficaFull])


    return (
        <>
            {loader && (
                <div className="w-full flex items-center justify-center">
                    <CircularProgress color="c-primary" classNames={{
                        svg: "w-36 h-36 drop-shadow-md",
                        track: "stroke-white/10",
                        value: "text-3xl font-semibold text-white",
                    }} />
                </div>
            )}

            {!loader && (
                <div>
                    <div className="flex flex-col gap-4 pt-9">
                        <h3 className="text-xl font-bold">Distribución de Inversiones</h3>

                        <Select
                            label="Selecione los bancos"
                            selectionMode="multiple"
                            className="max-w-xs self-end"
                            onSelectionChange={(e) => {
                                setLoader(true)
                                setBanksSelect(Array.from(e))
                                formatData(dataGraficaFull)
                            }}
                            selectedKeys={BanksSelect}
                        >
                            {bancos.map((banco) => (
                                <SelectItem key={banco} value={banco}>
                                    {banco}
                                </SelectItem>
                            ))}
                        </Select>
                        {(graph && !loader) && <ReactEcharts style={{ height: "450px" }} option={graph} />}

                        <p>
                            (3) Incluye acciones de S.A. cerradas, muebles y útiles, caja, derechos por inversiones en instrumentos derivados.
                        </p>
                    </div>

                    {info.map((item, index) => {
                        return (
                            <div key={index} className="flex flex-col gap-3 pt-6 font-semibold" >
                                <h3 className="text-xl font-bold">{item.title}</h3>
                                <TableAutomatic info={item} columns={item.columns} users={item.rows} selectRow={selectRow} />
                            </div>
                        )
                    })}


                </div>
            )}
        </>

    )
}
