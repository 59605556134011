import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useState } from "react"; /* 
import { getInfoBankTransaction } from "../../services/bankTransaction/bankTransaction"; */
import { formatStackedBar } from "./helper/formatData";
import { Select, SelectItem } from "@nextui-org/react";

function ActivosConsolidados({ data }) {
  const [dataSeriesState, setDataSeries] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [selectTransaction, setSelectTransaction] = useState([]);

  const getInfo = useCallback(
    async (selectTransactionData) => {
      console.log("selectTransactionData", selectTransactionData);
      const [info, infooTransactions] = formatStackedBar(
        data,
        selectTransactionData
      );

      console.log("info", info);
      setDataSeries(info);
      if (infooTransactions) {
        setTransactions(Array.from(infooTransactions));
        setSelectTransaction(Array.from(infooTransactions));
      }
    },
    [data]
  );

  useEffect(() => {
    getInfo([]);
  }, [data]);

  return (
    <div className="w-full">
      <h1 className="text-xl font-bold mb-1">Activos Consolidados</h1>

      <div className="flex flex-col gap-4">
        <Select
          label="Selecione los bancos"
          selectionMode="multiple"
          className="max-w-xs self-end"
          onSelectionChange={(e) => {
            getInfo(Array.from(e));
            setSelectTransaction(Array.from(e));
          }}
          selectedKeys={selectTransaction}
        >
          {transactions.map((banco) => (
            <SelectItem key={banco} value={banco}>
              {banco}
            </SelectItem>
          ))}
        </Select>
      </div>

      <ReactEcharts
        style={{ height: "450px", width: "100%" }}
        option={dataSeriesState}
        notMerge={true}
      />
    </div>
  );
}

export default ActivosConsolidados;
