import React, { useEffect, useState } from "react";
import { Tabs, Tab, Card, CardBody } from "@nextui-org/react";
import { servicioCarnesVInos } from "../../services/otros/otros";
export default function Otros() {
  const [iamgesCarnes, setImagesCarnes] = useState([]);
  const [iamgesVinos, setImagesVinos] = useState([]);
  const getData = async () => {
    const dataCarne = await servicioCarnesVInos("carnes");
    setImagesCarnes(dataCarne.data[0].img);

    const dataVino = await servicioCarnesVInos("vinos");
    setImagesVinos(dataVino.data[0].img);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex w-full flex-col">
      <Tabs aria-label="Options">
        <Tab key="carnes" title="Carnes">
          <Card>
            <CardBody>
              {iamgesCarnes.length > 0 &&
                iamgesCarnes.map((image) => {
                  return (
                    <img
                      src={`https://backendreportes.inversionesm.cl/api/v1/servicio-medico/img/${image}`}
                    />
                  );
                })}
            </CardBody>
          </Card>
        </Tab>
        <Tab key="vino" title="Vinos">
          <Card>
            <CardBody>
              {iamgesVinos.length > 0 &&
                iamgesVinos.map((image) => {
                  return (
                    <img
                      src={`https://backendreportes.inversionesm.cl/api/v1/servicio-medico/img/${image}`}
                    />
                  );
                })}
            </CardBody>
          </Card>
        </Tab>
      </Tabs>
    </div>
  );
}
