import currencyFormatter from "currency-formatter";

export function formatCurrencyNoDecimal(value) {
  return currencyFormatter.format(value, {
    decimal: ",",
    thousand: ".",
    symbol: "$",
    precision: 0,
  });
}

export const convertSlug = (str) => {
  return str
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const formatNumber = (value) => {
  const data = Number(value) > 0 ? Number(value) / 1000000 : 0;

  //max 2 digitos
  return data.toFixed(2);
};
