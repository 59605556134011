import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { formatDataLinerSpearedFromYear } from "./helper/formatData";
import PropTypes from "prop-types";

export default function HistoricoColocaciones({ data }) {
  const [dataSeriesState, setDataSeries] = useState([]);
  const [leyendState, setLeyendState] = useState([]);
  const [xAxisState, setXAxisState] = useState({});

  const formatData = (data) => {
    const newData = [];

    data.forEach((element) => {
      const Transaction = [];

      const filterCode145000000 = element.Transaction.find(
        (e) => e.code_account === "145000000"
      );
      const filterCode146000000 = element.Transaction.find(
        (e) => e.code_account === "146000000"
      );
      const filterCode148000000 = element.Transaction.find(
        (e) => e.code_account === "148000000"
      );
      const filterCode149500100 = element.Transaction.find(
        (e) => e.code_account === "149500100"
      );
      const filterCode149600100 = element.Transaction.find(
        (e) => e.code_account === "149600100"
      );
      const filterCode149700100 = element.Transaction.find(
        (e) => e.code_account === "149700100"
      );

      Transaction.push({
        ...filterCode145000000,
        amount_total:
          filterCode145000000.amount_total +
          filterCode149500100.amount_total +
          (filterCode148000000.amount_total +
            filterCode149700100.amount_total) +
          (filterCode146000000.amount_total + filterCode149600100.amount_total),
      });

      newData.push({ ...element, Transaction });
    });

    const { legend, xAxis, series } = formatDataLinerSpearedFromYear(newData);

    console.log(legend);
    console.log(xAxis);
    console.log(series);

    setLeyendState(legend);
    setXAxisState(xAxis);
    setDataSeries(series);
  };

  useEffect(() => {
    formatData(data);
  }, [data]);

  return (
    <div>
      <div>
        <h3 className="text-xl font-bold mb-5">
          Histórico de Colocaciones <br /> Totales BCI
        </h3>
      </div>
      <ReactEcharts
        style={{ width: "100%" }}
        option={{
          ...dataEchart.option,
          legend: leyendState,
          xAxis: xAxisState,
          series: dataSeriesState,
        }}
        notMerge={true}
      />
    </div>
  );
}

HistoricoColocaciones.propTypes = {
  data: PropTypes.array.isRequired,
};

const dataEchart = {
  option: {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
      orient: "vertical",
      right: 0,
      top: "center",
    },
    grid: {
      height: "100%",
      left: "0%",
      right: "3%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      min: function (value) {
        return (value.min - 5).toFixed(0);
      },

      type: "value",
    },
    series: [],
  },
};
