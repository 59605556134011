export const GET_INFO_BANK_TRANSACTION = `/api/v1/bank-transaction/transaction-code-totals`;

export const GET_PAGINATION_NEWS = `/api/v1/news/pagination`;

export const GET_ENUM_NEWS = `/api/v1/enum/news`;
export const GET_ENUM_SEARCH_NEWS = `/api/v1/enum/enumSearchNews`;

export const GET_BANKS = `/api/v1/bank`;

export const GET_SERVICIO_MEDICO = `/api/v1/servicio-medico/:table`;
export const GET_SERVICIO_MEDICO_INVERSIONES = `/api/v1/servicio-medico/inversiones`;
export const GET_SERVICIO_MEDICO_INVERSIONES_COMPANIA = `/api/v1/servicio-medico/inversiones-compania`;

export const GET_RENTABILIDAD_BANCA = `/api/v1/reporte-informacion-financiera`;

export const GET_IMPORTES_EXTERIOR = `/api/v1/Importes-exterior`;

export const GET_INFO_BANK_RESUMEN = `/api/v1/bank-transaction/resumen`;
