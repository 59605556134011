export const clearSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const ReverseSlug = (text) => {
  const textRever = text.replace(/-/g, " ").replace(/_/g, " ");

  //capitalize
  return textRever.charAt(0).toUpperCase() + textRever.slice(1);
};
