import ReactEcharts from "echarts-for-react";

import { useEffect, useState } from "react";
import { getRentabilidadBanca } from "../../services/RentabilidadBanca/rentabilidadBanca_helper";
import TableAutomatic from "../seguros/component/TableAutomatic";
import { convertSlug, formatNumber } from "../../helper/formatCurrency";
import { getImportesExterior } from "../../services/ImportesExterior/ImportesExterior";
import { ReverseSlug } from "../../helper/formatText";

let option = {
  title: {
    text: "Stacked Line",
  },
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: [],
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: {
    type: "value",
  },
};

export default function ImportesExterior() {
  const [dataFiliales, setDataFiliales] = useState([]);
  const [dataSucursales, setDataSucursales] = useState([]);
  const [dataBancosColocaciones, setDataBancosColocaciones] = useState({});

  const getInfo = async () => {
    const data = await getImportesExterior();

    const sort = data
      .sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year;
        }
        return a.month - b.month;
      })
      .reverse();

    const formatColumns = {};

    const seriesColocacionesComercialesSucursales = [];
    const LegenColocacionesComercialesSucursales = new Set();

    const legend = new Set();

    const xAxis = new Set();

    const seriesBancosColocaciones = [];

    sort.forEach((element) => {
      xAxis.add(element.Fecha);
      element.Bancos.forEach((banco) => {
        banco.Activos.forEach((activos) => {
          Object.entries(activos).forEach(([key, value]) => {
            if (key === "type") {
              return;
            }

            if (!formatColumns[activos.type]) {
              formatColumns[activos.type] = {};
            }

            /* 1000000000000 */

            if (!formatColumns[activos.type][convertSlug(banco.Nombre)]) {
              legend.add(convertSlug(banco.Nombre));
              formatColumns[activos.type][convertSlug(banco.Nombre)] = {
                [convertSlug(key)]: [formatNumber(value)],
              };
            } else {
              if (
                !formatColumns[activos.type][convertSlug(banco.Nombre)][
                  convertSlug(key)
                ]
              ) {
                formatColumns[activos.type][convertSlug(banco.Nombre)][
                  convertSlug(key)
                ] = [formatNumber(value)];
              } else {
                formatColumns[activos.type][convertSlug(banco.Nombre)][
                  convertSlug(key)
                ].push(formatNumber(value));
              }
            }
          });
        });
      });

      element.BancosColocaciones.forEach((colocaciones) => {
        const findIndex = seriesColocacionesComercialesSucursales.findIndex(
          (e) => e.name === ReverseSlug(colocaciones.Nombre)
        );
        console.log("colocaciones", colocaciones);
        if (findIndex === -1) {
          LegenColocacionesComercialesSucursales.add(
            ReverseSlug(colocaciones.Nombre)
          );
          console.log("new");
          seriesColocacionesComercialesSucursales.push({
            name: ReverseSlug(colocaciones.Nombre),
            type: "line",
            data: [formatNumber(colocaciones.Sum)],
            label: {
              show: true,
              position: "bottom",
              fontSize: 15,
            },
            labelLayout: {
              hideOverlap: true,
              dy: 10,
            },
          });
        } else {
          seriesColocacionesComercialesSucursales[findIndex].data.push(
            formatNumber(colocaciones.Sum)
          );
        }
      });
    });

    const series = [];

    Object.entries(formatColumns).forEach(([key, value]) => {
      const format = {
        type: key,
        data: [],
      };
      Object.entries(value).forEach(([key, value]) => {
        Object.entries(value).forEach(([keyI, value]) => {
          const findIndexBancoInfo = format.data.findIndex(
            (e) => e.banco === key && e.type === keyI
          );

          if (findIndexBancoInfo === -1) {
            format.data.push({
              banco: key,
              type: keyI,
              value: [value],
            });
          }
        });
      });

      series.push({ ...format });
    });

    const filiales = [];
    const sucursales = [];
    const totales = [];

    series.forEach((element) => {
      const format = [];
      element.data.forEach((element) => {
        const findIndex = format.findIndex((e) => {
          return e.type === element.type;
        });

        if (findIndex === -1) {
          format.push({
            type: element.type,
            series: [
              {
                name: ReverseSlug(element.banco),
                type: "line",
                data: [...element.value[0]],
                label: {
                  show: true,
                  position: "bottom",
                  fontSize: 15,
                },
                labelLayout: {
                  hideOverlap: true,
                  dy: 10,
                  moverOverlap: "shiftY",
                },
              },
            ],
          });
        } else {
          format[findIndex].series.push({
            name: ReverseSlug(element.banco),
            type: "line",
            data: [...element.value[0]],
            label: {
              show: true,
              position: "bottom",
              fontSize: 15,
            },
            labelLayout: { hideOverlap: true, dy: 10, moverOverlap: "shiftY" },
          });
        }
      });
      if (element.type === "sucursal") {
        sucursales.push(format);
      } else {
        filiales.push(format);
      }
    });

    option.xAxis.data = Array.from(xAxis);

    setDataFiliales(...filiales);
    setDataSucursales(...sucursales);

    const optionsBancoColocaciones = {
      ...option,
      xAxis: {
        data: Array.from(xAxis),
      },
      title: {
        text: "",
      },
      legend: {
        ...option.legend,
        data: Array.from(LegenColocacionesComercialesSucursales),
      },
      series: seriesColocacionesComercialesSucursales,
    };

    console.log("optionsBancoColocaciones", optionsBancoColocaciones);

    setDataBancosColocaciones(optionsBancoColocaciones);
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          fontSize: "1.6rem",
          alignSelf: "flex-start",
        }}
        className="text-xl font-bold align-self-start "
      >
        Totales generales
      </h1>

      {Object.keys(dataBancosColocaciones).length > 0 && (
        <ReactEcharts
          style={{ height: "450px", width: "100%" }}
          option={dataBancosColocaciones}
          notMerge={true}
        />
      )}

      <h1
        style={{
          fontSize: "1.6rem",
          alignSelf: "flex-start",
        }}
        className="text-xl font-bold align-self-start "
      >
        Filiales
      </h1>

      {dataFiliales.map((element, index) => {
        const legend = new Set();

        element.series.forEach((e) => {
          legend.add(e.name);
        });

        let show = false;

        element.series.forEach((e) => {
          e.data.forEach((element) => {
            if (element > 0) {
              show = true;
            }
          });
        });

        if (!show) return null;

        const dataFUll = {
          ...option,
          legend: {
            ...option.legend,
            data: Array.from(legend),
          },
          title: {
            text: "",
          },
          series: [...element.series],
        };

        return (
          <div key={`${index}-${element.type}`} className="w-full h-full p-4">
            <h3 className="text-2xl w-full mb-4">
              {ReverseSlug(element.type)}
            </h3>
            <ReactEcharts
              style={{ height: "450px", width: "100%" }}
              key={index}
              option={dataFUll}
              notMerge={true}
            />
          </div>
        );
      })}

      <h1
        style={{
          fontSize: "1.6rem",
          alignSelf: "flex-start",
        }}
        className="text-xxl font-bold mt-8"
      >
        Sucursales
      </h1>

      {dataSucursales.map((element, index) => {
        const legend = new Set();

        element.series.forEach((e) => {
          legend.add(e.name);
        });

        option.legend.data = Array.from(legend);

        let show = false;

        element.series.forEach((e) => {
          e.data.forEach((element) => {
            if (element > 0) {
              show = true;
            }
          });
        });

        if (!show) return null;

        const dataFUll = {
          ...option,
          title: {
            text: "",
          },
          series: [...element.series],
        };

        return (
          <div key={`${index}-${element.type}`} className="w-full h-full p-4">
            <h3 className="text-2xl w-full mb-4">
              {ReverseSlug(element.type)}
            </h3>
            <ReactEcharts
              style={{ height: "450px", width: "100%" }}
              key={index}
              option={dataFUll}
              notMerge={true}
            />
          </div>
        );
      })}
    </div>
  );
}

/* {
  "_id": {
    "$oid": "66875f6d741f4aa288222ba8"
  },
  "fecha": "Julio 2024",
  "year": 2024,
  "month": 7,
  "bancos": [
    {
      "nombre": "banco-del-estado-de-chile",
      "activos": [
        {
          "colocaciones-para-vivienda": "0",
          "adeudado-por-bancos": "0",
          "type": "sucursal",
          "colocaciones-comerciales": "0",
          "colocaciones-de-consumo": "0"
        }
      ]
    },
    {
      "nombre": "banco-de-credito-e-inversiones",
      "activos": [
        {
          "colocaciones-de-consumo": "0",
          "colocaciones-para-vivienda": "0",
          "adeudado-por-bancos": "242899417.48000002",
          "type": "sucursal",
          "colocaciones-comerciales": "40775082.44"
        },
        {
          "type": "filial",
          "colocaciones-comerciales": "233643723.92",
          "colocaciones-de-consumo": "4502769.84",
          "colocaciones-para-vivienda": "100061552",
          "adeudado-por-bancos": "51531699.28"
        }
      ]
    },
    {
      "nombre": "itau-corpbanca",
      "activos": [
        {
          "type": "sucursal",
          "colocaciones-comerciales": "15759694.44",
          "colocaciones-de-consumo": "0",
          "colocaciones-para-vivienda": "0",
          "adeudado-por-bancos": "0"
        },
        {
          "type": "filial",
          "colocaciones-comerciales": "57785546.28",
          "colocaciones-de-consumo": "70293240.28",
          "colocaciones-para-vivienda": "23514464.72",
          "adeudado-por-bancos": "0"
        }
      ]
    },
    {
      "nombre": "banco-btg-pactual-chile",
      "activos": [
        {
          "adeudado-por-bancos": "0",
          "type": "filial",
          "colocaciones-comerciales": "3252000.44",
          "colocaciones-de-consumo": "0",
          "colocaciones-para-vivienda": "0"
        }
      ]
    }
  ],
  "createdat": {
    "$date": "2024-07-05T02:50:21.726Z"
  }
} */
