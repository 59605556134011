import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { formatDataLiner } from "./helper/formatData";
import PropTypes from "prop-types";

export default function ColocacionesConsumo({ data }) {
  const [dataSeriesState, setDataSeries] = useState([]);
  const [leyendState, setLeyendState] = useState([]);
  const [xAxisState, setXAxisState] = useState({});

  const formatData = (data) => {
    const newData = [];

    data.forEach((element) => {
      const Transaction = [];
      const filterCode148000000 = element.Transaction.find(
        (e) => e.code_account === "148000000"
      );

      const filterCode149700100 = element.Transaction.find(
        (e) => e.code_account === "149700100"
      );

      Transaction.push({
        ...filterCode148000000,
        amount_total:
          filterCode148000000.amount_total + filterCode149700100.amount_total,
      });

      newData.push({ ...element, Transaction });
    });

    const { legend, xAxis, series } = formatDataLiner(newData);
    setLeyendState(legend);
    setXAxisState(xAxis);
    setDataSeries(series);
  };

  useEffect(() => {
    formatData(data);
  }, [data]);

  return (
    <div>
      <h1 className="text-xl font-bold mb-5">Colocaciones Consumo</h1>
      <ReactEcharts
        style={{ height: "300px" }}
        option={{
          ...dataEchart.option,
          legend: leyendState,
          xAxis: xAxisState,
          series: dataSeriesState,
        }}
        notMerge={true}
      />
    </div>
  );
}

ColocacionesConsumo.propTypes = {
  data: PropTypes.array.isRequired,
};

const dataEchart = {
  option: {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
      orient: "vertical",
      right: 10,
      top: "center",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
      min: function (value) {
        if (value.min - 5 < 0) {
          return 0;
        }
        return (value.min - 5).toFixed(0);
      },
    },
    series: [],
  },
};
