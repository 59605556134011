import axios from "axios";


class APIClient {
    constructor(storei) {
        const request = axios.create({
            baseURL: import.meta.env.VITE_API_URL,
        });
        this.request = request;
        this.store = storei;
        this.request.interceptors.response.use(
            this.responseSuccess,
            this.responseError
        );
        this.request.interceptors.request.use((config) => {
            const token = localStorage.getItem("authUser");
            if (token) {
                const parsedToken = JSON.parse(token);
                config.headers.common["Authorization"] = `Bearer ${parsedToken.token}`;
            }
            config.headers["Content-Type"] = "application/json";

            return config;
        });
    }

    header = (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.common["Authorization"] = `Bearer ${token}`;
        }

        config.headers.post["Content-Type"] = "application/json";
    };

    responseSuccess = (response) => {
        return response.data ? response.data : response;
    };

    responseError = (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        switch (error.response.status) {
            case 500:
                message = "Error de servidor";
                break;
            case 401:
                this.store.dispatch({ type: "KILL_SESSION" });
                message = "Invalid credentials";
                break;
            case 404:
                message = error.response.data;
                break;
            default:
                message = error.response.data || error;
        }

        return Promise.reject(message);
    };
    /**
     * Fetches data from given url
     */

    //  get = (url, params) => {
    //   return axios.get(url, params);
    // };
    get = (url, params) => {
        let response;

        if (params) {
            const paramKeys = Object.keys(params).map((key) => {
                if (Array.isArray(params[key])) {
                    // Si el valor del parámetro es un array, generamos múltiples parámetros con el mismo nombre
                    return params[key].map((value) => `${key}=${value}`).join("&");
                } else {
                    // Si el valor del parámetro no es un array, lo enviamos normalmente
                    return `${key}=${params[key]}`;
                }
            });

            const queryString = paramKeys.join("&");
            response = this.request.get(`${url}?${queryString}`);
        } else {
            response = this.request.get(`${url}`);
        }

        return response;
    };

    getFile = (url, params, config) => {
        let response;

        let paramKeys = [];

        if (params) {
            Object.keys(params).map((key) => {
                paramKeys.push(key + "=" + params[key]);
                return paramKeys;
            });

            const queryString =
                paramKeys && paramKeys.length ? paramKeys.join("&") : "";
            response = this.request.get(`${url}?${queryString}`, config);
        } else {
            response = this.request.get(`${url}`, config);
        }

        return response;
    };

    post = (url, data) => {
        return this.request.post(url, data);
    };

    /**
     * post given data to url
     */
    create = (url, data) => {
        return this.request.post(url, data);
    };
    /**
     * Updates data
     */
    update = (url, data) => {
        return this.request.patch(url, data);
    };

    put = (url, data) => {
        return this.request.put(url, data);
    };
    /**
     * Delete
     */
    delete = (url, config) => {
        return this.request.delete(url, { ...config });
    };
}

export const aPIClient = new APIClient();
