import "./App.css";
import Pages from "./page/menu";

function App() {
  return (
   <Pages />
  );
}

export default App;
