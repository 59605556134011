/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Pagination,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";

import { GetPaginationNews } from "../../services/news/news";
import { GetEnumNews, GetEnumSearchNews } from "../../services/enum/enum";
import moment from "moment";

const INITIAL_VISIBLE_COLUMNS = ["title", "source", "search", "date"];

export default function News() {
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState(["bci"]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "date",
    direction: "descending",
  });
  const [page, setPage] = React.useState(1);

  const [rows, setRows] = React.useState([]);
  const [pages, setPages] = React.useState(1);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const columns = React.useMemo(
    () => [
      { name: "ID", uid: "id", sortable: false },
      { name: "Titulo", uid: "title", sortable: false },
      { name: "Source", uid: "source", sortable: true },
      { name: "Search", uid: "search", sortable: false },
      { name: "Fecha", uid: "date", sortable: true },
    ],
    [rows]
  );

  const hasSearchFilter = Boolean(filterValue);

  const getInfo = useCallback(
    async (filter) => {
      console.log("filter", statusFilter.length);

      const data = await GetPaginationNews({
        page: page,
        PageSize: rowsPerPage,
        keyword: filterValue,
        searches: searchValue,
        sources: statusFilter,
        order: sortDescriptor.direction === "descending" ? "-1" : "1",
        ...filter,
      });

      const pages = Math.ceil(data.count / rowsPerPage);
      setRows(data.data || []);
      setPages(pages);
    },
    [statusFilter, page, rowsPerPage, filterValue, searchValue, sortDescriptor]
  );

  const getEnum = async () => {
    const eunm = await GetEnumNews({});
    const enumSearchNews = await GetEnumSearchNews({});
    setStatusOptions(eunm.res);
    setSearchFilter(enumSearchNews.res);
  };

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  useEffect(() => {
    getEnum();
  }, []);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;

    return columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [columns, visibleColumns, rows]);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = rows;
    return filteredUsers;
  }, [
    rows,
    hasSearchFilter,
    statusFilter,
    statusOptions.length,
    filterValue,
    searchFilter,
  ]);

  const renderCell = React.useCallback(
    (user, columnKey) => {
      console.log("user", user);
      const cellValue = user[columnKey];

      switch (columnKey) {
        case "title":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-small capitalize">
                <a href={user.link} target="_blank" rel="noreferrer">
                  {cellValue}
                </a>
              </p>
            </div>
          );
        case "date":
          return (
            <div className="flex flex-col">
              <p className="text-small">
                {moment(cellValue).format("DD/MM/YYYY")}
              </p>
            </div>
          );

        default:
          return cellValue;
      }
    },
    [rows]
  );

  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
      getInfo({
        page: page + 1,
      });
    }
  }, [page, pages, getInfo]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
      getInfo({
        page: page - 1,
      });
    }
  }, [getInfo, page]);

  const onSearchChange = React.useCallback((value) => {
    console.log("value", value);
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const topContent = React.useMemo(() => {
    return (
      <>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between gap-3 items-end">
            <Input
              isClearable
              className="w-full sm:max-w-[44%]"
              placeholder="Buscar por titulo"
              value={filterValue}
              onClear={() => onClear()}
              onValueChange={onSearchChange}
            />
            <div></div>
            <div className="flex gap-3">
              <Select
                label="Source"
                placeholder=""
                selectionMode="multiple"
                className="max-w-[200px] min-w-[200px]"
                onSelectionChange={(e) => {
                  setStatusFilter(Array.from(e));
                }}
              >
                {statusOptions.map((status) => (
                  <SelectItem key={status.slug} value={status.slug}>
                    {status.name}
                  </SelectItem>
                ))}
              </Select>

              <Select
                label="Search"
                placeholder=""
                selectionMode="multiple"
                className="max-w-[200px] min-w-[200px]"
                defaultSelectedKeys={["bci"]}
                onSelectionChange={(e) => {
                  console.log("e", e);
                  setSearchValue(Array.from(e));
                }}
              >
                {searchFilter.map((status) => (
                  <SelectItem key={status.slug} value={status.slug}>
                    {status.name}
                  </SelectItem>
                ))}
              </Select>

              {/* 
                   <AutocompleteSelect options={options} onSelect={handleSelect} /> */}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-default-400 text-small"></span>
            <label className="flex items-center text-default-400 text-small">
              Filtrar por página
              <select
                className="bg-transparent outline-none text-default-400 text-small"
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(1);
                }}
                value={rowsPerPage}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </label>
          </div>
        </div>
      </>
    );
  }, [
    rowsPerPage,
    statusFilter,
    getInfo,
    page,
    filterValue,
    statusOptions,
    searchFilter,
    rows,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <span className="w-[30%] text-small text-default-400">
          {selectedKeys === "all"
            ? "All items selected"
            : `${selectedKeys.size} de ${filteredItems.length} selected`}
        </span>
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={(page) => {
            setPage(page);
          }}
        />
        <div className="hidden sm:flex w-[30%] justify-end gap-2">
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onPreviousPage}
          >
            Anterior
          </Button>
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onNextPage}
          >
            Siguiente
          </Button>
        </div>
      </div>
    );
  }, [selectedKeys, page, pages, hasSearchFilter, rows]);

  const items = React.useMemo(() => {
    return rows;
  }, [page, filteredItems, rowsPerPage, rows]);

  const sortedItems = React.useMemo(() => {
    console.log("items", items);
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];

      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [ items, rows]);

  return (
    <Table
      aria-label="Example table with custom cells, pagination and sorting"
      isHeaderSticky
      bottomContent={bottomContent}
      bottomContentPlacement="outside"
      classNames={{
        wrapper: "max-h-[600px]",
      }}
      selectedKeys={selectedKeys}
      sortDescriptor={sortDescriptor}
      topContent={topContent}
      topContentPlacement="outside"
      onSelectionChange={setSelectedKeys}
      onSortChange={(e) => {
        console.log("e", e);
        setSortDescriptor(e);
      }}
    >
      <TableHeader columns={headerColumns}>
        {(column) => {
          return (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          );
        }}
      </TableHeader>
      <TableBody emptyContent={"No hay datos"} items={sortedItems}>
        {(item) => {
          return (
            <TableRow key={item.id}>
              {(columnKey) => {
                return <TableCell>{renderCell(item, columnKey)}</TableCell>;
              }}
            </TableRow>
          );
        }}
      </TableBody>
    </Table>
  );
}
